import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.timers.js";
import debounce from "lodash/debounce";
import { ONBOARDING_STEP_MARKET_OVERVIEW_ID } from "~/utils/onboarding/stepIds";
import useBaseUtils from "~/functions/useBaseUtils";
import newsTopics from "~/utils/templates/newsTopics.json"; // FIXME:   NEED TO REMOVE THIS HUGE FILE IN PROD
export default defineComponent({
  props: {
    feedId: {
      type: String,
      "default": "test"
    },
    resObj: {
      type: Object,
      "default": function _default() {
        return {
          order: [],
          headlines: {},
          meta: {},
          news: {}
        };
      }
    },
    showTopics: {
      type: Boolean,
      "default": true
    },
    topics: {
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    newsHeight: {
      type: String,
      "default": "85vh"
    }
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      refCode = _useBaseUtils.refCode,
      dev = _useBaseUtils.dev,
      I18nFn = _useBaseUtils.I18nFn;
    var _useNuxtApp = useNuxtApp(),
      $vuetify = _useNuxtApp.$vuetify;
    var fetchOlder = ref(true);
    var interval = ref(null);
    var lazySearchText = ref(null);
    var searchText = ref(null); // text associated with outer autocomplete
    var dialogSearchText = ref(null); // text associated with dialog
    var dialogDisplayText = ref(null); // text associated with dialog
    var dialogFocused = ref(false);
    var saveSearchText = ref(null);
    var setLazySearchText = function setLazySearchText(newText) {
      // console.log("setLazySearchText to: ", newText)
      lazySearchText.value = newText;
    }; // const baseCategoryMap = {
    //   company_attr: "Company",
    //   hist_multiple: "Valuation",
    //   est_multiple: "Valuation",
    //   // fin_lineitem: "Financials",
    //   is: "Income Statement",
    //   bs: "Balance Sheet",
    //   cf: "Cash Flow",
    //   r: "Ratios",
    //   est_lineitem: "Estimates",
    // }
    var baseCategoryMap = {
      "Company Events": "Company Events",
      Region: "Region",
      Industry: "Industry",
      "Capital Markets": "Capital Markets",
      Macro: "Macro",
      Commodities: "Commodities",
      // Debt: "Debt",
      Other: "Other"
    };
    var baseCategorySet = new Set();
    Object.values(baseCategoryMap).forEach(function (v) {
      return baseCategorySet.add(v);
    });
    var debouncesetLazySearchText = debounce(setLazySearchText, 150); // const showDataTypeDialog = ref(
    //   topics.value && topics.value.value ? false : true
    // )
    var showDataTypeDialog = ref(false); // TODO: Translate baseCategoryMap and newsTopics?
    // const topicsBySection = newsTopics.reduce((acc, t) => {
    //   const s = t.section
    //   if (acc[s]) {
    //     acc[s].push(t)
    //   } else {
    //     acc[s] = [t]
    //   }
    //   return acc
    // }, {})
    // const orderedTopicsArr = Object.keys(baseCategoryMap).reduce(
    //   (acc, section) => {
    //     const sectionTopicsArr = topicsBySection[section]
    //     acc = acc.concat(sectionTopicsArr)
    //     return acc
    //   },
    //   []
    // )
    var autocompleteStyles = computed(function () {
      return {
        "--margin-top": $vuetify.breakpoint.xs ? "9px" : "6px"
      };
    });
    var lastNewsFetch = ref(new Date());
    return {
      ONBOARDING_STEP_MARKET_OVERVIEW_ID: ONBOARDING_STEP_MARKET_OVERVIEW_ID,
      autocompleteStyles: autocompleteStyles,
      refCode: refCode,
      dev: dev,
      I18nFn: I18nFn,
      newsTopics: newsTopics,
      fetchOlder: fetchOlder,
      interval: interval,
      lazySearchText: lazySearchText,
      searchText: searchText,
      dialogSearchText: dialogSearchText,
      dialogDisplayText: dialogDisplayText,
      saveSearchText: saveSearchText,
      debouncesetLazySearchText: debouncesetLazySearchText,
      dialogFocused: dialogFocused,
      showDataTypeDialog: showDataTypeDialog,
      baseCategorySet: baseCategorySet,
      baseCategoryMap: baseCategoryMap,
      // orderedTopicsArr,
      lastNewsFetch: lastNewsFetch
    };
  },
  computed: {
    error: function error() {
      return this.$store.state.trkd.error[this.feedId];
    },
    loading: function loading() {
      return this.$store.state.trkd.loading[this.feedId];
    },
    initLoading: function initLoading() {
      return this.$store.state.trkd.loading["".concat(this.feedId, "init")];
    },
    recentLoading: function recentLoading() {
      return this.$store.state.trkd.loading["".concat(this.feedId, "recent")];
    },
    olderLoading: function olderLoading() {
      return this.$store.state.trkd.loading["".concat(this.feedId, "older")];
    },
    newsTopicsFiltered: function newsTopicsFiltered() {
      var _this = this;
      var searchText = this.lazySearchText;
      var topicSet = new Set();
      this.topics.forEach(function (t) {
        return topicSet.add(t.value);
      }); // TODO: Will this prevent duplicates?
      if (typeof searchText === "string" && searchText.length > 0) {
        var queryFiltered = this.newsTopics.filter(function (f) {
          return topicSet.has(f.value) || _this.newsFilter(f, searchText);
        });
        var headersRemoved = queryFiltered.filter(function (f, i, arr) {
          if (arr.length - 1 === i) {
            if (f.header) {
              return false;
            }
          } else {
            var next = arr[i + 1];
            if (next.header && f.header) {
              return false;
            }
          }
          return true;
        });
        return headersRemoved;
      }
      return this.newsTopics;
    },
    dataTypeSearchError: function dataTypeSearchError() {
      if (this.newsTopicsFiltered.filter(function (f) {
        return !f.header;
      }).length <= 0) {
        // TODO: translate this phrase
        return "".concat(this.I18nFn("No Data Types match the search query"), ": ").concat(this.lazySearchText);
      }
      return null;
    },
    baseCategories: function baseCategories() {
      return Array.from(this.baseCategorySet); // return [
      //   "Company Events",
      //   "Industry",
      //   "Capital Markets",
      //   "Macro",
      //   "Commodities",
      //   "Other",
      // ]
    },
    filteredItemsByCategory: function filteredItemsByCategory() {
      var _this2 = this;
      var splitFinancials = true;
      var baseMap = this.baseCategories.reduce(function (acc, c) {
        acc[c] = [];
        return acc;
      }, {});
      return this.newsTopicsFiltered.reduce(function (acc, item) {
        if (!item.header) {
          // don't include headers in this boi
          var catKey = _this2.baseCategoryMap[item.section];
          if (acc[catKey]) {
            acc[catKey].push(item);
          }
          if (splitFinancials && item.finType) {
            var finKey = _this2.baseFinancialsMap[item.finType];
            if (acc[finKey]) {
              acc[finKey].push(item);
            }
          }
        }
        return acc;
      }, baseMap);
    },
    dialogHint: function dialogHint() {
      // TODO: translate me
      if (this.lazySearchText) {
        return "".concat(this.I18nFn("Data matching search"), ": ").concat(this.lazySearchText);
      } else {
        return "";
      }
    },
    topicsSet: function topicsSet() {
      var set = new Set();
      this.topics.forEach(function (t) {
        return set.add(t.value);
      });
      return set;
    }
  },
  watch: {
    showDataTypeDialog: function showDataTypeDialog(newValue) {
      if (newValue === true) {
        // this.dialogSearchText = this.saveSearchText
        this.dialogSearchText = "";
      }
      if (newValue === false) {
        this.searchTextUpdate(""); // if (this.topics && this.topics.value) {
        // } else {
        //   // this.searchText = this.saveSearchText
        // }
      }
    },
    topics: function topics() {
      this.fetchHeadlines();
    }
  },
  created: function created() {
    var _this3 = this;
    var fetchTime = new Date();
    if (this.resObj.order && this.resObj.order.length > 0) {
      this.fetchHeadlines("recent");
    } else {
      this.fetchHeadlines();
    }
    this.lastNewsFetch = fetchTime;
    var fetchInterval = 60000 * 5; // every 5 minutes
    this.interval = window.setInterval(function () {
      _this3.fetchHeadlines("recent");
    }, fetchInterval); // if(!this.dev) {
    // TODO: only add window event handlers in production
    // }
    window.onfocus = function () {
      var now = new Date();
      var refreshTimeDiff = 5 * 60 * 1000; // 5 minutes
      var fetchDiff = now - _this3.lastNewsFetch;
      if (isNaN(fetchDiff) || fetchDiff > refreshTimeDiff) {
        if (_this3.interval) {
          clearInterval(_this3.interval);
        }
        if (_this3.resObj.order && _this3.resObj.order.length > 0) {
          _this3.fetchHeadlines("recent");
        } else {
          _this3.fetchHeadlines();
        }
        var _fetchInterval = 60000 * 5; // every 5 minutes
        _this3.interval = window.setInterval(function () {
          _this3.fetchHeadlines("recent");
        }, _fetchInterval);
        _this3.lastNewsFetch = now;
      }
    };
    window.onblur = function () {
      if (_this3.interval) {
        clearInterval(_this3.interval);
      }
    };
  },
  beforeDestroy: function beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
  methods: {
    dataTypeSelected: function dataTypeSelected(dataType) {
      if (dataType !== null && dataType !== void 0 && dataType.value) {
        try {
          this.$refs.dataTypeSelect.blur(); // blur from autocomplete component
        } catch (error) {// no emptyblock lol
        }
        var alreadyAdded = this.topics.filter(function (f) {
          return f.value === dataType.value;
        });
        if (alreadyAdded.length > 0) {
          // the topic code has alread been added, leave function
          return;
        } // set searchText to null
        this.searchText = null;
        this.saveSearchText = null;
        this.lazySearchText = null; // this.showDataTypeDialog = false
        var newArr = this.topics.concat([dataType]);
        this.topicCodeChange(newArr);
      }
    },
    newsFilter: function newsFilter(item, queryText) {
      if (item.header) {
        return true;
      }
      var itemCode = item.value;
      var itemShort = item.s;
      var itemLong = item.l;
      var itemSection = item.abbr;
      var inItemCode = itemCode ? itemCode.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) : false;
      var inItemShort = itemShort ? itemShort.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) : false;
      var inItemLong = itemLong ? itemLong.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) : false;
      var inItemSection = itemSection ? itemSection.toLocaleLowerCase().includes(queryText.toLocaleLowerCase()) : false;
      return inItemCode || inItemShort || inItemLong || inItemSection;
    },
    topicCodeChange: function topicCodeChange(arr) {
      this.resetLazySearch("topicCodeMenu");
      this.$store.dispatch("trkd/updateCollection", {
        id: this.feedId,
        key: "topics",
        value: arr
      }); // TODO: nextTick call init fetchHeadlines with the new topic codes?
      this.searchText = null;
      this.saveSearchText = null;
      this.lazySearchText = null;
    },
    resetLazySearch: function resetLazySearch(ref) {
      this.$refs[ref].lazySearch = "";
    },
    fetchHeadlines: function fetchHeadlines() {
      var fetchType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "init";
      var payload = {
        id: this.feedId,
        fetchType: fetchType
      };
      if (this.topics && this.topics.length > 0) {
        payload.topics = this.topics;
      }
      this.$store.dispatch("trkd/fetchHeadlines", payload);
    },
    onIntersect: function onIntersect(entries) {
      // More information about these options
      // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
      if (this.dev) {// console.log("onIntersect Called")
      }
      this.isIntersecting = entries[0].isIntersecting;
    },
    toggleDataTypeDialog: function toggleDataTypeDialog() {
      var bool = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.showDataTypeDialog = bool;
    },
    dataTypeInputFocus: function dataTypeInputFocus() {
      var _this$topics; // console.log(
      //   `dataTypeInputFocus called search: ${this.searchText} save: ${this.saveSearchText}`
      // )
      if ((_this$topics = this.topics) !== null && _this$topics !== void 0 && _this$topics.value) {// user has already selected a value...
        // this.searchText = this.saveSearchText
      } else {
        this.searchText = this.saveSearchText;
      }
    },
    dialogDataTypeInputFocus: function dialogDataTypeInputFocus() {
      // console.log(
      //   `dialogDataTypeInputFocus called search: ${this.dialogSearchText} save: ${this.saveSearchText}`
      // )
      // this.dialogSearchText = ""
      this.dialogFocused = true;
      this.dialogSearchText = this.saveSearchText;
    },
    searchTextUpdate: function searchTextUpdate(text) {
      // I think this function is how I can intercept update of searchText
      // console.log("searchTextUpdate: ", text)
      if (text === null || typeof text !== "string") {
        // search has blur'd, ignore and don't reset anything
        this.searchText = "";
      } else {
        // user has input something, save the text &
        // set the new lazySearchValue
        this.saveSearchText = text;
        this.debouncesetLazySearchText(text);
      }
    },
    dialogSearchTextUpdate: function dialogSearchTextUpdate(text) {
      // I think this function is how I can intercept update of searchText
      // console.log("dialogSearchTextUpdate: ", text)
      if (text === null || typeof text !== "string") {// search has blur'd, ignore and don't reset anything
        // this.searchText = null
      } else {
        // user has input something, save the text &
        // set the new lazySearchValue
        this.saveSearchText = text;
        this.debouncesetLazySearchText(text);
      }
    }
  }
});